import React, { createContext, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { WebAppProvider, MainButton, BackButton } from '@vkruglikov/react-telegram-web-app';
import 'antd/dist/reset.css';
import { ITelegramUser, IWebApp } from './types';
import { TelegramProvider, useTelegram } from './TelegramProvider';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const MainApp = () =>{
  const { user, webApp } = useTelegram();
  console.log(user);
  
  const [smoothButtonsTransition, setSmoothButtonsTransition] = useState(false);
  return (
    <WebAppProvider options={{ smoothButtonsTransition }}>
      <TelegramProvider>
        <App onChangeTransition={() => setSmoothButtonsTransition(state => !state)}/>
      </TelegramProvider>
    </WebAppProvider>
  );
};
declare global {
  interface Window {
    Telegram: {
      WebApp: {
        close: () => void;
      }
    };
  }
}
root.render(
  <MainApp/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
